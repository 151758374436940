exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-architecture-js": () => import("./../../../src/pages/architecture.js" /* webpackChunkName: "component---src-pages-architecture-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-merch-js": () => import("./../../../src/pages/merch.js" /* webpackChunkName: "component---src-pages-merch-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-validator-js": () => import("./../../../src/pages/validator.js" /* webpackChunkName: "component---src-pages-validator-js" */),
  "component---src-templates-stake-single-js": () => import("./../../../src/templates/stake-single.js" /* webpackChunkName: "component---src-templates-stake-single-js" */)
}

